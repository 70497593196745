import http from "@/utils/request";


/**
 * 获取问卷详情
 * @param {
	 relationId
 } data
 */
export function getQuestionnaire(data) {
	return http({
		method: "post",
		url: "/baseSurveyQuestion/getAnsweredQuestionContentById.nd",
		data: data
	});
}

