import Question from '@/views/questionnaire/components/Question';
import {
	getQuestionnaire
} from "./api";
export default {
	name: "checkAnswer",
	components: {
		Question
	},
	data() {
		return {
			breadcrumbData: [{
					path: "/index",
					name: "index",
					title: "首页"
				},
				{
					path: "/questionnaire/answered",
					name: "toBeAswered",
					title: "已答问卷"
				},
				{
					path: "/questionnaire/checkAnswer",
					name: "checkAnswer",
					title: "查看问卷"
				},
			],
			id: '',
			title: '',
			explain: '', //描述
			questions: [],
			disabled: true,
			loading: false,
			isPhone: false
		};
	},
	watch: {},
	computed: {

	},
	created() {},
	mounted() {
		this.id = this.$route.query.questionId;
		this.isPhone = navigator.userAgent.match(
			/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
		);
		this.getQuestionnaire();
	},
	methods: {
		getQuestionnaire() {
			let data = {
				relationId: this.id
			}
			this.loading = true;
			let index = -1;
			getQuestionnaire(data).then(res => {
				if(res.data.code == 400) {
					this.$message.warning(res.data.msg)
					this.loading = false;
					return
				}
				this.title = res.data.title;
				this.explain = res.data.explain;
				let questions = res.data.answerList;

				questions.sort((a, b) => {
					return a.orderBy - b.orderBy
				})
				// questions[0].typeId='701'
				// questions[1].typeId='702'
				// questions[2].typeId='703'
				// questions[3].typeId='704'
				// questions[4].typeId='705'
				// questions[5].typeId='706'
				// questions[6].typeId='706'
				// questions[7].typeId='707'
				for (let item of questions) {
					item.val = ''
					let typeId = parseInt(item.typeId);
					switch (typeId) {
						case 701:
							item.type = 'radio'
							for (let i = 0; i < item.subItemDtoList.length; i++) {
								let option = item.subItemDtoList[i]
								if (option.checkedFlag) {
									item.val = i
									// item.subItemDtoList[i].answerBlank = item.answerBlank
								}
							}
							break;
						case 702:
							item.type = 'checkbox'
							break;
						case 703:
							item.type = 'select'
							break;
						case 704:
							item.type = 'input'
							item.val = item.answer;
							break;
						case 705:
							item.type = 'textarea'
							item.val = item.answer;
							break;
						case 707:
							item.type = 'slider';
							item.val = item.answer
							item.min = item.subItemDtoList[0].min
							item.minText = item.subItemDtoList[0].minExplain
							item.max = item.subItemDtoList[0].max
							item.maxText = item.subItemDtoList[0].maxExplain
							item.options = [];
							break;
						case 706:
							item.type = 'rate';
							for (let i = 0; i < item.subItemDtoList.length; i++) {
								let option = item.subItemDtoList[i]
								if (option.checkedFlag) {
									index = i
								}
							}
							item.val = index + 1
							break;
					}
					// item.isMust = item.isRequire == 1;
					// item.isMap = item.isMappingSelect == 1;
					// item.direction = item.explain;
					// item.rateStyle = item.style;
					// item.style = 'num'
					// item.answerContent = null;
					if (item.typeId != '707') {
						item.min = item.minSelect;
						item.max = item.maxSelect;
						let options = item.subItemDtoList;
						for (let option of options) {
							option.label = option.optionExplain;
							option.img = option.imageUrl;
							option.isFill = option.isBlank == 1;
							option.isMust = option.isRequire == 1;
							option.score = option.fraction;
						}
						options.sort((a, b) => {
							return a.orderBy - b.orderBy
						})
						item.subItemDtoList = options;
					}
				}
				this.questions = questions;
				this.loading = false;
			})
		},
        back() {
            this.$router.push({path: "answered"})
        }
	}
};
